import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "link",
      "style": {
        "position": "relative"
      }
    }}>{`Link`}</h1>
    <p>{`Links são utilizados como elementos de navegação. Também podem mudar a forma de como o conteúdo é apresentado (ex.: ver mais, mostrar tudo).`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize palavras ou frases curtas.`}</li>
      <li parentName="ul">{`Utilize labels descritivas que sejam consistentes com o destino do link. Evite labels como "Clique aqui", "Aqui".`}</li>
      <li parentName="ul">{`Se a ação resultar em troca ou alteração de dados, utilize o componente de `}<a parentName="li" {...{
          "href": "/components/button"
        }}>{`Botão`}</a>{`.`}</li>
    </ul>
    <Demo src='pages/components/link/Link' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      